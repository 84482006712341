import { isMobile } from 'react-device-detect';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { DefaultButton } from '../../components/Button/Button';
import {
  LabelInput,
  MoneyInput,
  TextInput,
} from '../../components/Input/Input';
import { fixMoneyQnt } from '../../core/utils/utils';
import { useEffect } from 'react';
import { InputText } from '../../components/Input/InputText';

const ModalServiceAesthetic = ({
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
}) => {
  useEffect(() => {
    onChange('quantity', 1);
  }, []);

  return (
    <div className="modal-service-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Serviços</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? '' : 'Salvar'}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? '' : 'Cancelar'}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div className="bodyshop-title">
          <text>
            {isNew ? (
              <TextInput
                value={values?.title}
                placeholder="Adicionar título"
                onChange={(event) =>
                  onChange('title', event.target.value.toUpperCase())
                }
                className="item-title"
              />
            ) : (
              <TextInput
                value={values?.title}
                placeholder={values.title}
                onChange={(event) =>
                  onChange('title', event.target.value.toUpperCase())
                }
                className="item-title"
              />
            )}
          </text>
        </div>
        <br />
        <div className="lower-aesthetic-div">
          <div style={{ paddingTop: '15px' }}>
            <text style={{ color: 'white', fontWeight: 500, fontSize: 14 }}>
              Tabela
            </text>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  className="first-service-size-content"
                  style={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <MoneyInput
                    className={'normal-size-input'}
                    onChange={(value) => onChange('p_value', value)}
                    label={'Valor'}
                    placeholder={'____'}
                    value={values?.p_value}
                    type="number"
                  />
                  <InputText
                    onBlur={(event) => {
                      if (event.target.value <= 0 || !event.target.value) {
                        onChange('quantity', 1);
                      }
                    }}
                    onChange={(event) => {
                      onChange('quantity', event.target.value);
                    }}
                    label={'Quantidade'}
                    value={values?.quantity}
                    type="number"
                  />
                  <MoneyInput
                    className={'last-hour-input'}
                    disabled={true}
                    value={
                      values.quantity && values.p_value
                        ? fixMoneyQnt(values?.quantity, values?.p_value)
                        : ''
                    }
                    label={'Total'}
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div
              style={{ display: 'flex', paddingBottom: 10 }}
              className="repeat-days-modal"
            >
              <div className="notification-days-mobile">
                <text
                  style={{
                    paddingTop: 28,
                    paddingRight: 5,
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'white',
                  }}
                >
                  {' '}
                  Notificar{' '}
                </text>
                <div
                  style={{
                    width: 155,
                    backgroundColor: '#67676a',
                    maxHeight: 50,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <LabelInput
                    className={'small-size-input-frequency'}
                    onChange={(value) => onChange('notification_days', value)}
                    placeholder={'___'}
                    value={values.notification_days}
                    label={''}
                    type="number"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'white',
                      marginRight: 20,
                      backgroundColor: '#67676a',
                    }}
                  >
                    dias
                  </div>
                </div>
              </div>
              <div className="notification-days-mobile">
                <text
                  className="repeat-text"
                  style={{
                    paddingTop: 28,
                    paddingRight: 15,
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'white',
                  }}
                >
                  {' '}
                  Repetir{' '}
                </text>
                <div
                  style={{
                    width: 155,
                    backgroundColor: '#67676a',
                    maxHeight: 50,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <LabelInput
                    className={'small-size-input-frequency'}
                    onChange={(value) => onChange('repeat', value)}
                    placeholder={'___'}
                    value={values.repeat}
                    label={''}
                    type="number"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'white',
                      marginRight: 20,
                      backgroundColor: '#67676a',
                    }}
                  >
                    vezes
                  </div>
                </div>
              </div>
            </div>
            <div>
              <LabelInput
                type={'textarea'}
                className="message-area-min"
                value={values.notification_msg}
                onChange={(value) => onChange('notification_msg', value)}
                placeholder={'Insira a mensagem da notificação'}
              />
            </div>
            <div className="modal-footer-table">
              {values && !values.id ? (
                <div />
              ) : (
                <DefaultButton
                  className="modal-footer-button delete"
                  label={'Excluir'}
                  icon={<BsFillTrashFill className="button-icon-cancel" />}
                  onClick={onRemove}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalServiceAesthetic;
