import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalBody } from "reactstrap";
import Modal from "react-modal";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import {
  
  CNPJInput,
  LabelInput,
  LabelRadios,
  CellphoneInput,
  RGInput,
  CEPInput,
  DateMaskInput,
  LabelCreatable,
} from "../../components/Input/Input";
import {
  BsCardText,
  BsFillTrashFill,
  BsPerson,
} from "react-icons/bs";
import { saveClient } from "../../core/utils/client";
import { alertError, alertSuccess } from "../../components/Toast";
import { DefaultButton } from "../../components/Button/Button";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { isMobile } from "react-device-detect";

const BudgetAddClient = ({
  setOpen,
  isOpen,
  setReload,
  origins,
  createOrigin,
}) => {
  const [values, setValues] = useState({});
  const company = useSelector((state) => state.app.company || {});

  const onSave = async () => {
    try {
      const client = { ...values };
      delete client.Vehicle;
      delete client.Origin;
      if (client && !client.name) {
        alertError("Nome é obrigatório");
        return;
      }

      if (!validateCellPhoneNumber(client.cellphone)) {
        alertError("Número de celular inválido");
        return;
      }

      const save = await saveClient(client.id, {
        ...client,
        id_company: company.id,
      });

      alertSuccess("Sucesso ao salvar o cliente!");

      setValues({});
      setOpen((open) => !open);
      setReload((reload) => !reload);
    } catch (error) {
      alertError("Erro ao salvar o cliente, tente novamente mais tarde.");
    }
  };

  const closeModal = () => {
    setOpen((open) => !open);
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };
  return (
    <Modal
      onRequestClose={closeModal}
      className={"prospera-modal"}
      isOpen={isOpen}
    >
      <input style={{ visibility: "hidden", position: "absolute" }} />
      <ModalBody className="modal-body">
        <div className="modal-container">
          <div className="modal-header">
            <div className="header-title">
              <h4 className="modal-title">{"Adicionar Cliente"}</h4>
            </div>
            <div className="header-buttons">
              <DefaultButton
                className="modal-footer-button"
                label={isMobile ? "" : "Salvar"}
                icon={<AiOutlineCheck className="button-icon" />}
                onClick={onSave}
              />
              <DefaultButton
                className="modal-footer-button cancel"
                label={isMobile ? "" : "Cancelar"}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
          </div>
          <Tabs className="main-tab">
            <TabList>
              <Tab>
                <text> Dados </text>
                <BsPerson />
              </Tab>
              <Tab>
                <text> Observações </text>
                <BsCardText />
              </Tab>
            </TabList>

            <TabPanel>
              <div className="desc-input company">
                <LabelInput
                  value={values.name}
                  onChange={(value) => onChange("name", value)}
                  placeholder={"Insira o nome"}
                  label={"Nome*"}
                />
                <div className="desc-line last">
                  <CellphoneInput
                    value={values.cellphone}
                    onChange={(value) => onChange("cellphone", value)}
                    placeholder={"Insira o celular"}
                    label={"Celular*"}
                  />
                  <CellphoneInput
                    value={values.telephone}
                    onChange={(value) => onChange("telephone", value)}
                    placeholder={"Insira o telefone"}
                    label={"Telefone"}
                    mask="(99) 9999-9999"
                  />
                </div>
                <LabelInput
                  value={values.email}
                  onChange={(value) => onChange("email", value)}
                  placeholder={"Insira o e-mail"}
                  label={"E-mail"}
                />
                <DateMaskInput
                  value={values.birthday}
                  onChange={(value) => onChange("birthday", value)}
                  placeholder={"DD/MM/AAAA"}
                  label={"Data de Nascimento"}
                />
                <LabelCreatable
                  value={values.origin}
                  onChange={(item) => onChange(`origin`, item)}
                  placeholder={"INSIRA A ORIGEM DO CLIENTE"}
                  label={"Origem"}
                  options={origins?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                  className="generic-input select-creatable"
                  formatCreateLabel={(value) => `Criar "${value}"`}
                  onCreateOption={createOrigin}
              />
                <LabelRadios
                  className="custom-radio"
                  options={radios}
                  value={personValue(values.person)}
                  onClick={(event) => onChange(`person`, event)}
                />
              
                {values && values.person && values.person.value == 2 ? (
                  <CNPJInput
                    value={values.cnpj}
                    onChange={(value) => onChange("cnpj", value)}
                    placeholder={"Insira o CNPJ"}
                    label={"CNPJ"}
                  />
                ) : (
                  <CNPJInput
                    value={values.cpf}
                    onChange={(value) => onChange("cpf", value)}
                    placeholder={"Insira o CPF"}
                    label={"CPF"}
                  />
                )}
                {values && values.person && values.person.value === 1 ? (
                  <RGInput
                    value={values.rg}
                    onChange={(value) => onChange("rg", value)}
                    placeholder={"Insira o RG"}
                    label={"RG"}
                  />
                ) : (
                  <div />
                )}
                <CEPInput
                  value={values.cep}
                  onChange={(value) => onChange("cep", value)}
                  placeholder={"Insira o CEP"}
                  label={"CEP"}
                />
              </div>
              <div className="desc-line">
                <LabelInput
                  value={values.street}
                  onChange={(value) => onChange("street", value)}
                  placeholder={"Rua"}
                  label={"Rua"}
              />
                <LabelInput
                  value={values.number}
                  onChange={(value) => onChange("number", value)}
                  placeholder={"Insira o número"}
                  label={"Número"}
              />
                <LabelInput
                  value={values.complement}
                  onChange={(value) => onChange("complement", value)}
                  placeholder={"Insira o Complemento"}
                  label={"Complemento"}
              />
              </div>
              <LabelInput
                value={values.neighborhood}
                onChange={(value) => onChange("neighborhood", value)}
                placeholder={"Bairro"}
                label={"Bairro"}
            />
              <div className="desc-line last">
                <LabelInput
                  value={values.city}
                  onChange={(value) => onChange("city", value)}
                  placeholder={"Cidade"}
                  label={"Cidade"}
              />
                <LabelInput
                  value={values.state}
                  onChange={(value) => onChange("state", value)}
                  placeholder={"UF"}
                  label={"UF"}
              />
              </div>
            </TabPanel>

            {<div />}

            <TabPanel>
              <LabelInput
                type={"textarea"}
                value={values.observation}
                onChange={(value) => onChange("observation", value)}
                placeholder={"Insira a observação"}
                inputClassname={"text-area-min"}
                label={"Observação"}
              />
            </TabPanel>
          </Tabs>
          {values && !values.id ? (
            <div />
          ) : (
            <DefaultButton
              className="modal-footer-button delete"
              label={"Excluir"}
              icon={<BsFillTrashFill className="button-icon-cancel" />}
              onClick={onRemove}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BudgetAddClient;

const validateCellPhoneNumber = (cellphone) => {
  const regex = /^\(\d{2}\)\s\d{5}\-\d{4}$/;
  return regex.test(cellphone);
};

const personValue = (value) => {
  if (value) {
    if (value === 1) {
      return { value: 1, label: "Pessoa fisica", field: "physical" };
    } else if (value === 2) {
      return { value: 2, label: "Pessoa juridica", field: "legal" };
    } else {
      return value;
    }
  }
};

const radios = [
  { value: 1, label: "Pessoa fisica", field: "physical" },
  { value: 2, label: "Pessoa juridica", field: "legal" },
];
