import React from 'react';
import { DefaultButton } from '../../../components/Button/Button';
import {
  AiOutlinePrinter,
  AiOutlineSend,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import { Spinner } from 'reactstrap';
import { BsFillTrashFill } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { useReactToPrint } from 'react-to-print';
import { saveBudget } from '../../../core/utils/budget';
import './styles.scss';

export const ActionsButton = (props) => {
  const {
    sendWhatsapp,
    toShare,
    isLoading,
    values,
    onRemove,
    compareValues,
    onSave,
  } = props;

  if (values && !values.id) {
    return;
  }

  const printRef = React.useRef();
  const originalTitle = document.title;
  const toPrint = () =>
    !compareValues
      ? handlePrint()
      : Swal.fire({
          title: 'Salve o conteúdo para poder imprimir o PDF.',
          showCancelButton: true,
          showConfirmButton: true,
          showDenyButton: false,
          icon: 'warning',
          confirmButtonText: `Salvar`,
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            onSave(handlePrint);
          } else {
            history.push('/budgets');
          }
        });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,

    onBeforeGetContent: () => {
      const newTitle = `Orcamento_${values.code}_${values.client_name}_${values.vehicle_name}}`;
      document.title = newTitle;
      return Promise.resolve();
    },

    onAfterPrint: () => {
      document.title = originalTitle;
    },

    onBeforePrint: async () => {
      const budgetId = values.id;
      if (values?.shared_at === null) {
        await saveBudget(budgetId, {
          shared_at: new Date(),
        });
      }
    },
  });

  return (
    <div style={{ marginBottom: 16 }}>
      <div
        className="header-title budget-action-button-container"
        style={{ gap: isMobile ? 10 : 20 }}
      >
        <div className="mt-2-5_d-flex" style={{ flex: isMobile ? 1 : 0 }}>
          <DefaultButton
            className="modal-print modal-footer-button cancel margin-0"
            label={'IMPRIMIR'}
            icon={<AiOutlinePrinter className="button-icon-cancel" />}
            onClick={toPrint}
          />
          <DefaultButton
            className="modal-footer-button cancel to-share-button"
            label={'ENVIAR'}
            icon={<AiOutlineWhatsApp className="button-icon-cancel" />}
            onClick={sendWhatsapp}
          />
        </div>
        <DefaultButton
          className="modal-footer-button cancel margin-0"
          label={'COMPARTILHAR'}
          icon={<AiOutlineSend className="button-icon-cancel" />}
          onClick={toShare}
          disabled={values && isLoading}
        />
        {isLoading && (
          <div className="spinner-clear">
            <Spinner color="primary" size="sm" />
          </div>
        )}
        <DefaultButton
          className="modal-footer-button delete"
          label={'Excluir'}
          icon={<BsFillTrashFill className="button-icon-cancel" />}
          onClick={onRemove}
        />
      </div>
    </div>
  );
};
