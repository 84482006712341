import React from 'react';
import {
  HourInput,
  MoneyInput,
  NumberInput,
  PercentageInput,
} from '../../../components/Input/Input';
import { FormGroup, Label } from 'reactstrap';
import {
  fixMoney,
  fixMoneyQnt,
  totalHour,
  totalQnt,
  totalValue,
  totalValueQnt,
  valuePerHour,
  valuePerQnt,
} from '../../../core/utils/utils';

export const RelatedServices = (props) => {
  const { values, setValues, onChange } = props;
  if (!values) {
    return;
  }

  const applyConstDiscount = (value) => {
    value = Number((value ?? '0').replace(',', '.'));
    const finalValue = applyDiscount(value, values, 'constant');
    setValues((val) => ({
      ...val,
      finalValue,
      p_value: null,
      discount: null,
      discount_percentage: null,
      discount_percentage_temp:
        value && value != 0
          ? ((value / totalValueQnt(values.relatedServices)) * 100).toFixed(2)
          : null,
    }));
  };
  const applyDiscount = (value, values, type) => {
    if (type == 'constant') {
      return totalValueQnt(values.relatedServices) - value;
    }
    return totalValueQnt(values.relatedServices) * (1 - value / 100);
  };
  const applyPercentDiscount = (value) => {
    value = Number((value ?? '0').replace(',', '.'));
    const finalValue = applyDiscount(value, values, 'percent');
    setValues((val) => ({
      ...val,
      finalValue,
      p_value: null,
      discount: null,
      discount_percentage: null,
      discount_temp:
        value && value != 0
          ? (totalValueQnt(values.relatedServices) - finalValue).toFixed(2)
          : null,
    }));
  };

  return (
    <div>
      {values?.relatedServices?.map((st, index) => (
        <div key={index} style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <div className="first-service-size-content" style={{ padding: 0 }}>
              <FormGroup className="normal-size-input">
                {index == 0 && <Label>Serviço</Label>}
                <Label style={{ marginTop: '0.5rem', width: 'inherit' }}>
                  {st.title}
                </Label>
              </FormGroup>
              <NumberInput
                className='normal-size-input'
                onChange={(value) => onChange("p_time", value < 1 ? 1 : value)}
                label={index == 0 && 'Quantidade'}
                value={st?.p_time}
                type='number'
                disabled={true}
              />
              {/* <HourInput
                className={'hour-input'}
                onChange={(value) => onChange('p_time', value)}
                label={index == 0 && 'Horas'}
                value={st.p_time}
                type="number"
                disabled={true}
              /> */}
              <MoneyInput
                className={'normal-size-input'}
                onChange={(value) => onChange('p_value', value)}
                label={index == 0 && 'Valor'}
                placeholder={'____'}
                value={st.p_value}
                type="number"
                disabled={true}
              />
              <MoneyInput
                className={'last-hour-input'}
                disabled={true}
                value={
                  st.p_time && st.p_value ? fixMoneyQnt(st.p_time, st.p_value) : ''
                }
                label={index == 0 && 'Total'}
                type="number"
              />
            </div>
          </div>
        </div>
      ))}

      <div style={{ display: 'flex', borderTop: '1px solid white' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
          }}
        >
          <div className="first-service-size-content" style={{ padding: 0 }}>
            <FormGroup className="normal-size-input">
              <Label style={{ marginTop: '0.5rem', width: 'inherit' }}>
                Total
              </Label>
            </FormGroup>
            <NumberInput
              className='normal-size-input'
              onChange={(value) => onChange("p_time", value < 1 ? 1 : value)}
              value={totalQnt(values.relatedServices)}
              type='number'
              disabled={true}
            />
            {/* <HourInput
              className={'hour-input'}
              onChange={(value) => onChange('p_time', value)}
              value={totalHour(values.relatedServices)}
              type="number"
              disabled={true}
            /> */}
            <MoneyInput
              className={'normal-size-input'}
              onChange={(value) => onChange('p_value', value)}
              placeholder={'____'}
              value={valuePerQnt(values.relatedServices)}
              type="number"
              disabled={true}
            />
            <MoneyInput
              className={'last-hour-input'}
              disabled={true}
              value={totalValueQnt(values.relatedServices)}
              type="number"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          borderTop: '1px solid white',
          flexDirection: 'column',
          paddingTop: 8,
        }}
      >
        <MoneyInput
          type="number"
          className="edit-piece-modal-input"
          disabled={values?.discount_percentage ? true : false}
          prefix={'R$'}
          label={'Desconto R$'}
          value={values?.discount || values?.discount_temp}
          onChange={(value) => {
            applyConstDiscount(value);
            onChange('discount', value);
          }}
        />
        <PercentageInput
          placeholder={'%'}
          className="edit-piece-modal-input"
          disabled={values?.discount !== undefined && values?.discount !== null}
          label={'Desconto %'}
          value={
            values?.discount_percentage || values?.discount_percentage_temp
          }
          onChange={(value) => {
            applyPercentDiscount(value);
            onChange('discount_percentage', value);
          }}
        />
        <MoneyInput
          type="number"
          className="edit-piece-modal-input"
          disabled={true}
          prefix={'R$'}
          label={'Valor Final R$'}
          value={
            values?.p_value ||
            values?.finalValue ||
            totalValueQnt(values?.relatedServices)
          }
          onChange={(value) => onChange('finalValue', value)}
        />
      </div>
    </div>
  );
};
