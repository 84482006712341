import React from 'react';
import Selector from '../../../../components/Selector';
import { DetailServices } from './DetailServices';
import { BodyshopServices } from './BodyshopServices';
import { Parts } from './Parts';
import { Tables } from './Tables';
import { AestheticsServices } from './AestheticsServices';
import { ActionsButton } from '../ActionsButton';
import { TotalBudget } from '../TotalBudget';
import { BUDGET_TABS } from '../../utils/enum';

export const BudgetTabs = (props) => {
  const {
    budget,
    showBudgetItems,
    onChange,
    client,
    values,
    setOpen,
    vehicle,
    menuOpen,
    creatableOpen,
    createOption,
    divCreatable,
    deleteOptions,
    paymentOptions,
    company,
    isFullscreen,
    isntLinearHour,
    hvTable,
    setIsntLinearHour,
    bodyshopService,
    onRemoveService,
    changeValue,
    onRemovePiece,
    changePartsValue,
    openEditModal,
    parts,
    isOpen,
    onRemoveAeService,
    aeService,
    compareValues,
    sendWhatsapp,
    toShare,
    isLoading,
    onRemove,
    onSave,
    totalParts,
    totalSum,
    totalBodyshopService,
    totalAeService,
    changeValueAeService,
  } = props;

  return (
    <div className="modal-content-wrapper">
      <div className="main-content-modal1">
        <div style={{ textAlign: 'center' }}>
          {isFullscreen ? (
            budget === BUDGET_TABS.DETAILS ? (
              <div className="full-screen-tabs-container">
                <Selector
                  handleClick={() => showBudgetItems(1)}
                  buttonClass={budget === 1 ? 'highlighted' : ''}
                  id={'Detalhes'}
                  name={'Detalhes'}
                />
                <Selector
                  handleClick={() => showBudgetItems(4)}
                  buttonClass={budget === 4 ? 'highlighted' : ''}
                  id={'Tabelas'}
                  name={'Tabelas'}
                />
              </div>
            ) : null
          ) : (
            <div>
              <Selector
                handleClick={() => showBudgetItems(1)}
                buttonClass={`${budget === 1 ? 'highlighted' : ''} mr-1`}
                id={'Detalhes'}
                name={'Detalhes'}
              />
              <Selector
                handleClick={() => showBudgetItems(2)}
                buttonClass={`${budget === 2 ? 'highlighted' : ''} mr-1`}
                id={'ServiçosFunilaria'}
                name={'Serviços Funilaria'}
              />
              <Selector
                handleClick={() => showBudgetItems(5)}
                buttonClass={`${budget === 5 ? 'highlighted' : ''} mr-1`}
                id={'ServiçosEstetica'}
                name={'Serviços Estética'}
              />
              <Selector
                handleClick={() => showBudgetItems(3)}
                buttonClass={`${budget === 3 ? 'highlighted' : ''}`}
                id={'Peças'}
                name={'Peças'}
              />
            </div>
          )}
        </div>

        <DetailServices
          budget={budget}
          onChange={onChange}
          client={client}
          values={values}
          setOpen={setOpen}
          vehicle={vehicle}
          menuOpen={menuOpen}
          creatableOpen={creatableOpen}
          createOption={createOption}
          divCreatable={divCreatable}
          deleteOptions={deleteOptions}
          paymentOptions={paymentOptions}
          company={company}
        />
        <BodyshopServices
          budget={budget}
          values={values}
          onChange={onChange}
          isntLinearHour={isntLinearHour}
          hvTable={hvTable}
          setIsntLinearHour={setIsntLinearHour}
          setOpen={setOpen}
          bodyshopService={bodyshopService}
          onRemoveService={onRemoveService}
          changeValue={changeValue}
        />
        <Parts
          budget={budget}
          isOpen={isOpen}
          setOpen={setOpen}
          onRemovePiece={onRemovePiece}
          changePartsValue={changePartsValue}
          openEditModal={openEditModal}
          parts={parts}
        />
        <Tables
          budget={budget}
          showBudgetItems={showBudgetItems}
          isOpen={isOpen}
          setOpen={setOpen}
          onRemovePiece={onRemovePiece}
          changePartsValue={changePartsValue}
          openEditModal={openEditModal}
          parts={parts}
          values={values}
          onChange={onChange}
          setIsntLinearHour={setIsntLinearHour}
          isntLinearHour={isntLinearHour}
          hvTable={hvTable}
          onRemoveService={onRemoveService}
          changeValue={changeValue}
          bodyshopService={bodyshopService}
          onRemoveAeService={onRemoveAeService}
          aeService={aeService}
        />
        <AestheticsServices
          budget={budget}
          setOpen={setOpen}
          onRemoveAeService={onRemoveAeService}
          values={values}
          changeValueAeService={changeValueAeService}
          aeService={aeService}
        />
        <ActionsButton
          compareValues={compareValues}
          sendWhatsapp={sendWhatsapp}
          toShare={toShare}
          isLoading={isLoading}
          values={values}
          onRemove={onRemove}
          onSave={onSave}
        />

        <TotalBudget
          budget={budget}
          totalParts={totalParts}
          totalSum={totalSum}
          totalBodyshopService={totalBodyshopService}
          totalAeService={totalAeService}
        />
      </div>
    </div>
  );
};
