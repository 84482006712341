import react from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setData } from "../../core/redux/app/Actions";

const RegisterScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector(state => state.app.company || {})

  return (
    <div className="dashboard-placement">
      <div className="dashboard-home">
        <div>
          <img
            className="dashboard-logo"
            src={require("../../assets/images/web.png").default}
          />
        </div>
        <div className="dashboard-button-placement">
          <div
            className="location-button"
            onClick={() => {
              history.push(`/modules`);
              dispatch(setData({ navigate: `/modules` }));
            }}
          >
            <text>Módulos Funilaria</text>
          </div>
          <div
            style={company?.id != 160 ? { display: 'none' } : {}}
            className="location-button"
            onClick={() => {
              history.push(`/modules-aesthetic`);
              dispatch(setData({ navigate: `/modules-aesthetic` }));
            }}
          >
            <text>Módulos Estética</text>
          </div>
          <div
            className="location-button"
            onClick={() => {
              history.push(`/company`);
              dispatch(setData({ navigate: `/company` }));
            }}
          >
            <text>Empresas</text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
