export const getTotalSum = (bodyshopService, parts, aeService) => {
  let totalSum = 0;
  for (const item of bodyshopService) {
    totalSum += parseFloat(item.value) || 0;
  }
  for (const item of parts) {
    totalSum += parseFloat(item.value) || 0;
  }
  for (const item of aeService) {
    totalSum += parseFloat(item.value) || 0;
  }

  return totalSum;
};
