export const changeValueBodyshop = ({
  array,
  field,
  data,
  value,
  edited = false,
}) => {
  return array.map((item) => {
    if (item.id === data.id && field === 'time') {
      let fixedPrice = item.unitaryValue;
      const sanitizedFixedPrice =
        typeof fixedPrice === 'string'
          ? parseFloat(fixedPrice.replace(',', '.'))
          : parseFloat(
              (item?.unitaryValue || 'R$').toString().replace(/[^\d.-]/g, '0')
            );

      const newValue = calculateTotalValue(value, sanitizedFixedPrice);
      return {
        ...item,
        [field]: value,
        value: newValue,
        editedHour: edited,
      };
    } else if (item.id === data.id && field === 'unitaryValue') {
      let fixedTime = item.time;

      const sanitizedFixedPrice =
        value !== undefined && value !== null && value !== 0
          ? value.toString()
          : 0;

      const newValue = calculateTotalValue(
        fixedTime,
        sanitizedFixedPrice
          ? `${sanitizedFixedPrice}`.replace(',', '.')
          : sanitizedFixedPrice
      );

      return {
        ...item,
        [field]: sanitizedFixedPrice,
        value: newValue,
        editedPrice: true,
      };
    } else {
      return item;
    }
  });
};

export const partDetails = ({ array, field, data, value, part }) => {
  return array.map((item) => {
    if (item.id === data.id && field === 'quantity') {
      const unitValueString = item?.unitaryValue;
      const unitValue =
        typeof unitValueString === 'string'
          ? parseFloat(unitValueString.replace(',', '.'))
          : parseFloat(
              (part?.unitaryValue || 'R$').toString().replace(/[^\d.-]/g, '0')
            );
      const bruteProfit = (item) => {
        if (item.profit !== '') {
          const profitString = item?.profit;
          const profit =
            typeof profitString === 'string'
              ? parseFloat(profitString.replace(',', '.'))
              : parseFloat(
                  (part?.profit || 'R$').toString().replace(/[^\d.-]/g, '0')
                );
          const bruteProfit = (profit + unitValue) * value;

          return bruteProfit;
        } else {
          const percentageProfit =
            ((item?.profit_margin || 0) / 100) * unitValue;
          const bruteValuePercentageProfit =
            (percentageProfit + unitValue) * value;
          return bruteValuePercentageProfit;
        }
      };
      const bruteDiscount = (item, value) => {
        if (item.discount !== '') {
          const discountString = item?.discount;
          const formatedDiscount =
            typeof discountString === 'string'
              ? parseFloat(discountString.replace(',', '.'))
              : parseFloat(
                  (item?.discount || 'R$').toString().replace(/[^\d.-]/g, '0')
                );
          const bruteDiscount = bruteProfit(item, value) - formatedDiscount;
          return bruteDiscount;
        } else {
          const percentageDiscount =
            (1 - item?.discount_percentage / 100) * bruteProfit(item, value);
          return percentageDiscount;
        }
      };
      const newValue = bruteDiscount(item, value);
      return {
        ...item,
        [field]: value,
        value: newValue,
      };
    } else if (item.id === data.id && field === 'unitaryValue') {
      const unitValue = parseFloat((value || '').replace(/[^\d.-]/g, '0'));
      const bruteProfit = (item) => {
        const profitString = item?.profit;
        const formatedProfit =
          typeof profitString === 'string'
            ? parseFloat(profitString.replace(',', '.'))
            : parseFloat(
                (item?.profit || 'R$').toString().replace(/[^\d.-]/g, '0')
              );
        if (item.profit !== '') {
          const bruteProfit =
            ((formatedProfit || '0') + unitValue) * item.quantity;
          return bruteProfit;
        } else {
          const percentageProfit =
            ((item?.profit_margin || 0) / 100) * unitValue;
          const bruteValuePercentageProfit =
            (percentageProfit + unitValue) * item.quantity;
          return bruteValuePercentageProfit;
        }
      };
      const discountString = item?.discount;
      const formatedDiscount =
        typeof discountString === 'string'
          ? parseFloat(discountString.replace(',', '.'))
          : parseFloat(
              (item?.discount || 'R$').toString().replace(/[^\d.-]/g, '0')
            );
      const bruteDiscount = (item, value) => {
        if (item.discount !== '') {
          const bruteDiscount = bruteProfit(item, value) - formatedDiscount;
          return bruteDiscount;
        } else {
          const percentageDiscount =
            (1 - (item?.discount_percentage || 0) / 100) *
            bruteProfit(item, value);
          return percentageDiscount;
        }
      };
      const newValue = bruteDiscount(item, value);
      return {
        ...item,
        [field]: parseFloat(unitValue),
        value: newValue,
      };
    } else {
      return item;
    }
  });
};

export const partValues = ({ array, field, data, value }) => {
  return array.map((item) => {
    if (item.id === data.id && field === 'quantity') {
      let fixedPrice = item.unitaryValue;
      const sanitizedFixedPrice =
        typeof fixedPrice === 'string'
          ? parseFloat(fixedPrice.replace(',', '.'))
          : parseFloat(
              (item.unitaryValue || 'R$').toString().replace(/[^\d.-]/g, '0')
            );

      let newValue = sanitizedFixedPrice * value;

      return {
        ...item,
        [field]: value,
        value: newValue,
      };
    } else if (item.id === data.id && field === 'unitaryValue') {
      let fixedQuantity = item.quantity;
      let unitValueString = value;
      const unitValue =
        typeof unitValueString === 'string'
          ? parseFloat(unitValueString.replace(',', '.'))
          : parseFloat((value || 'R$').toString().replace(/[^\d.-]/g, '0'));

      let newValue = unitValue * fixedQuantity;

      return {
        ...item,
        [field]: value,
        value: newValue,
      };
    } else {
      return item;
    }
  });
};

export const changeValueAeServiceFn = ({
  array,
  field,
  data,
  value,
  edited = false,
}) => {
  return array.map((item) => {
    if (item.id === data.id && field === 'quantity') {
      let fixedPrice = item.unitaryValue;
      const sanitizedFixedPrice =
        typeof fixedPrice === 'string'
          ? parseFloat(fixedPrice.replace(',', '.'))
          : parseFloat(
              (item?.unitaryValue || 'R$').toString().replace(/[^\d.-]/g, '0')
            );

      const newValue = calculateTotalValue(value, sanitizedFixedPrice);
      return {
        ...item,
        [field]: value,
        value: newValue,
        editedHour: edited,
      };
    } else if (item.id === data.id && field === 'unitaryValue') {
      const sanitizedFixedPrice =
        value !== undefined && value !== null && value !== 0
          ? value.toString()
          : 0;

      const totalValue =
        item.quantity * sanitizedFixedPrice?.replace?.(',', '.');

      return {
        ...item,
        [field]: sanitizedFixedPrice,
        value: totalValue,
        editedPrice: true,
      };
    } else {
      return item;
    }
  });
};

// Calcula o valor total, independente de qual caso acima tenha sido aplicado
export const calculateTotalValue = (hours, unitaryValue) => {
  if (
    unitaryValue === undefined ||
    unitaryValue === '' ||
    isNaN(unitaryValue) ||
    !unitaryValue ||
    hours === undefined ||
    hours === '' ||
    !hours
  ) {
    return 0;
  }

  // Verifica se hours é um número e converte para o formato "hh:mm" se for
  let totalMinutes;
  if (typeof hours === 'number') {
    totalMinutes = hours * 60; // Se for número, trata como horas inteiras
  } else if (typeof hours === 'string' && hours.includes(':')) {
    const [hoursInt, minutesDecimal] = hours.split(':');
    totalMinutes = parseInt(hoursInt, 10) * 60 + parseFloat(minutesDecimal);
  } else {
    return 0; // Caso o formato de hours não seja nem número nem string válida
  }

  if (isNaN(totalMinutes)) {
    return 0;
  }

  const unitaryValueStr =
    typeof unitaryValue === 'string'
      ? unitaryValue.replace(',', '.')
      : unitaryValue.toString();
  const unitaryValueMinute = unitaryValueStr / 60;

  if (isNaN(unitaryValueMinute) || unitaryValueMinute < 0) {
    return 0;
  }

  const total = totalMinutes * unitaryValueMinute;

  return total.toFixed(3);
};
