import React from 'react';
import { useDispatch } from 'react-redux';
import { setData } from '../../core/redux/app/Actions';
import { useHistory } from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';
import { version } from '../../../package.json';

export default function DashboardScreen() {
  const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="dashboard-placement">
      <div className="dashboard-home">
        <div>
          <img
            className="dashboard-logo"
            src={require('../../assets/images/web.png').default}
          />
        </div>
        <div className="dashboard-button-placement">
          <div
            className="location-button"
            onClick={() => {
              history.push(`/budgets`);
              dispatch(setData({ navigate: `/budgets` }));
            }}
          >
            <text>Orçamento</text>
          </div>
          <div
            className="deactivated-button"
            // onClick={() => {
            //   history.push(`/budgets`);
            //   dispatch(setData({ navigate: `/budgets` }));
            // }}
          >
            <text>Ordem de serviço</text>
          </div>
          <div
            className="deactivated-button"
            // onClick={() => {
            //   history.push(`/company`);
            //   dispatch(setData({ navigate: `/company` }));
            // }}
          >
            <text>Pátio</text>
          </div>
          <div
            className="location-button"
            onClick={() => {
              history.push(`/clients`);
              dispatch(setData({ navigate: `/clients` }));
            }}
          >
            <text>Clientes</text>
          </div>
        </div>
        <div className="dashboard-button-placement">
          <div
            className="location-button"
            onClick={() => {
              history.push(`/vehicles`);
              dispatch(setData({ navigate: `/vehicles` }));
            }}
          >
            <text>Veículos</text>
          </div>
          <div className="twin-buttons">
            <div
              className="deactivated-button"
              // onClick={() => {
              //   history.push(`/modules`);
              //   dispatch(setData({ navigate: `/modules` }));
              // }}
            >
              <text>Administração</text>
            </div>
            <div
              className="location-button"
              onClick={() => {
                history.push(`/register`);
                dispatch(setData({ navigate: `/register` }));
              }}
            >
              <text>Cadastro</text>
            </div>
          </div>
        </div>
      </div>

      <div className="text-version">
        <div>{version}</div>
        <div>{latestVersion}</div>
      </div>
    </div>
  );
}
