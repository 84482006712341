import moment from 'moment';
import React, { useEffect } from 'react';

const ExternalBudgetPrint = React.forwardRef((props, ref, reloadPrint) => {
  const serviceArray = props?.values?.service
    ? JSON.parse(props.values.service)
    : null;
  const aeServiceArray = props?.values?.ae_service
    ? JSON.parse(props.values.ae_service)
    : null;
  const partsArray = props?.values?.parts
    ? JSON.parse(props.values.parts)
    : null;
  const clientFound = props?.client.find(
    (client) => client?.label === props?.values?.client_name
  );

  const addRow = (tableBody, serviceArray, type) => {
    serviceArray.sort((a, b) => a?.title?.localeCompare(b.title));
    serviceArray.forEach(function (item) {
      var row = document.createElement('tr');

      // Cria a coluna 1 (column1) com uma div para conter múltiplos elementos
      var column1 = document.createElement('td');
      var column1Div = document.createElement('div');

      // Adiciona o título principal na div
      var titleSpan = document.createElement('span');
      titleSpan.textContent = item?.title;
      column1Div.appendChild(titleSpan);

      // Verifica se o tipo é 'aesthetic' e se item.services existe e é um array
      if (
        type === 'aesthetic' &&
        Array.isArray(item.services) &&
        item.services.length > 0
      ) {
        // Cria um elemento adicional para exibir os detalhes de services
        var servicesDetails = document.createElement('div');
        servicesDetails.style.marginTop = '5px'; // Espaçamento opcional

        // Adiciona cada serviço como um item de lista (ou outro elemento)
        item.services.forEach((service) => {
          var serviceSpan = document.createElement('span');
          serviceSpan.textContent = service.title; // Exibe o serviço
          serviceSpan.style.display = 'block'; // Exibe cada serviço em uma nova linha
          serviceSpan.style.color = 'gray';
          serviceSpan.style.fontSize = '12px';
          servicesDetails.appendChild(serviceSpan);
        });

        // Adiciona os detalhes de services na div
        column1Div.appendChild(servicesDetails);
      }

      // Adiciona a div completa à coluna 1
      column1.appendChild(column1Div);

      var column2 = document.createElement('td');
      column2.textContent =
        type === 'aesthetic' ? item?.quantity || 1 : item?.time;

      var column3 = document.createElement('td');
      column3.textContent =
        'R$' +
        (item?.unitaryValue === null || item.unitaryValue === 'undefined'
          ? '0.00'
          : parseFloat(item?.unitaryValue).toFixed(2));

      var column4 = document.createElement('td');
      column4.textContent =
        'R$' +
        (item?.value !== 'NaN' ? parseFloat(item?.value).toFixed(2) : '0');

      row.appendChild(column1);
      row.appendChild(column2);
      row.appendChild(column3);
      row.appendChild(column4);

      tableBody.appendChild(row);
    });
    return tableBody;
  };

  useEffect(() => {
    function populateServiceTable() {
      const tableBody = document.querySelector('#serviceTable tbody');
      if (!tableBody) return;
      tableBody.innerHTML = '';

      addRow(tableBody, serviceArray);
      addRow(tableBody, aeServiceArray, 'aesthetic');
    }

    function populatePartsTable() {
      const tableBody = document.querySelector('#partsTable tbody');
      if (!tableBody) return;
      tableBody.innerHTML = '';

      partsArray.sort((a, b) => a?.name?.localeCompare(b.name));
      partsArray.forEach(function (item) {
        const formatUnitaryValue =
          typeof item.unitaryValue !== 'undefined' && item.unitaryValue !== null
            ? item.unitaryValue.toString()
            : '0';
        const unitValue = parseFloat(
          (formatUnitaryValue || 'R$').replace(/[^\d.-]/g, '0')
        );
        var row = document.createElement('tr');
        var column1 = document.createElement('td');
        column1.textContent = item?.piece_code;
        var column2 = document.createElement('td');
        column2.textContent = item?.name;
        var column3 = document.createElement('td');
        column3.textContent = item?.quantity ? item.quantity : '0';
        var column4 = document.createElement('td');
        column4.textContent = 'R$' + unitValue;
        var column5 = document.createElement('td');
        column5.textContent =
          'R$' + (item?.value ? parseFloat(item?.value).toFixed(2) : '0');

        row.appendChild(column1);
        row.appendChild(column2);
        row.appendChild(column3);
        row.appendChild(column4);
        row.appendChild(column5);

        tableBody.appendChild(row);
      });
    }

    populateServiceTable();
    populatePartsTable();
  }, [serviceArray, partsArray]);

  if (!props.values || !props.values.vehicle_name) {
    return <></>;
  }

  const [placa, veiculo] = props.values.vehicle_name.split(' / ');

  return (
    clientFound && (
      <div
        className="dont-show-but-print"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: '100vh',
          position: 'absolute',
        }}
        ref={ref}
      >
        <div className="print-padding-mobile">
          <div style={{ display: 'flex' }}>
            {props.company.logo ? (
              <img
                className="print-logo"
                src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${props.company.logo}`}
              />
            ) : (
              <></>
            )}
            {/* Cabeçalho */}
            <div style={{ width: '100%' }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingBottom: '5px',
                }}
              >
                <text style={{ fontSize: '20px', color: 'black' }}>
                  ORÇAMENTO: {props.values.code}
                </text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ fontSize: '14px' }}>{props.company.name}</text>{' '}
                <text style={{ fontSize: '14px' }}>Emissão</text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ fontSize: '14px' }}>{props.company.cnpj}</text>{' '}
                {moment(props.values.shared_at).isValid()
                  ? moment(props.values.shared_at).format('DD/MM/YYYY')
                  : moment().format('DD/MM/YYYY')}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ fontSize: '14px' }}>
                  {props.company.street}, {props.company.neighborhood},{' '}
                  {props.company.city}, {props.company.state},{' '}
                  {props.company.complement}
                </text>
                <text style={{ fontSize: '14px' }}>{props.company.cep}</text>
                <text style={{ fontSize: '14px' }}>Validade</text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ fontSize: '14px' }}>
                  Fone: {props.company.telephone}
                </text>{' '}
                <text style={{ fontSize: '14px' }}>
                  Whastapp: {props.company.cellphone}
                </text>
                {props.values.expiration ? (
                  moment(props.values.shared_at).isValid() ? (
                    moment(props.values.shared_at)
                      .add(props.values.expiration, 'days')
                      .format('DD/MM/YYYY')
                  ) : (
                    moment()
                      .add(props.values.expiration, 'days')
                      .format('DD/MM/YYYY')
                  )
                ) : (
                  <div>--/--/----</div>
                )}
              </div>
            </div>
          </div>
          {/* Area cliente, cpf/cnpj/ telefone */}
          <div
            style={{
              backgroundColor: 'black',
              width: '100%',
              height: '1px',
              marginTop: '1px',
              marginBottom: '3px',
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <text
                style={{
                  fontSize: '14px',
                  color: 'black',
                  paddingRight: '30px',
                }}
              >
                Cliente:
              </text>
              <text style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                {props.values.client_name}
              </text>
              <div>
                <text>Veículo: </text>
                <text>{veiculo}</text>
              </div>
            </div>
            <text style={{ fontSize: '14px', color: 'black' }}>
              CPF/CNPJ: {clientFound.cpf}
              <div>
                <text>Placa: </text>
                <text>{placa}</text>
              </div>
            </text>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <text style={{ fontSize: '14px', color: 'black' }}>
                Fone: {clientFound.telephone}
              </text>
              <text style={{ fontSize: '14px', color: 'black' }}>
                WhatsApp: {clientFound.cellphone}
              </text>
            </div>
          </div>
          {/* Area de serviços */}
          <div
            style={{
              backgroundColor: 'black',
              width: '100%',
              height: '1px',
              marginTop: '1px',
              marginBottom: '3px',
            }}
          />
          <div style={{ paddingBottom: '20px' }}>
            <div
              style={{
                backgroundColor: '#dddddd',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <text style={{ fontSize: '14px', color: 'black' }}>
                RELAÇÃO DOS SERVIÇOS
              </text>
            </div>
            <table id="serviceTable">
              <tr>
                <th>Descrição</th>
                <th style={{ width: '135px' }}>Qtdade / Horas</th>
                <th style={{ width: '100px' }}>Valor Unit.</th>
                <th style={{ width: '100px' }}>Valor Total</th>
              </tr>
              <tbody></tbody>
            </table>
          </div>
          {/* Area de peças */}
          <div style={{ paddingBottom: '20px' }}>
            <div
              style={{
                backgroundColor: '#dddddd',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <text style={{ fontSize: '14px', color: 'black' }}>
                RELAÇÃO DAS PEÇAS
              </text>
            </div>
            <table id="partsTable">
              <tr>
                <th style={{ width: '100px' }}>Cód Peça</th>
                <th>Descrição</th>
                <th style={{ width: '70px' }}>Qtdade</th>
                <th style={{ width: '100px' }}>Valor Unit.</th>
                <th style={{ width: '100px' }}>Valor Total</th>
              </tr>
              <tbody></tbody>
            </table>
          </div>
          {/* Parte final do documento */}
          <div
            style={{
              backgroundColor: 'black',
              width: '100%',
              height: '1px',
              marginTop: '1px',
              marginBottom: '3px',
              marginBottom: '20px',
            }}
          />
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <text
                style={{
                  backgroundColor: '#dddddd',
                  fontSize: '14px',
                  color: 'black',
                }}
              >
                TOTAL SERVIÇOS:{' '}
                {props?.totalServices?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </text>
              <text
                style={{
                  backgroundColor: '#dddddd',
                  fontSize: '14px',
                  color: 'black',
                }}
              >
                TOTAL PEÇAS:{' '}
                {props?.totalParts?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </text>
              <text
                style={{
                  backgroundColor: '#dddddd',
                  fontSize: '14px',
                  color: 'black',
                }}
              >
                TOTAL GERAL:{' '}
                {props?.totalSum?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </text>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '15px',
                paddingBottom: '30px',
                flexDirection: 'column',
              }}
            >
              {props?.values?.payment?.label ? (
                <text style={{ fontSize: '14px' }}>
                  Condição de pagamento: {props?.values?.payment?.label}
                </text>
              ) : (
                <></>
              )}
              {props?.values?.deadline ? (
                <text style={{ fontSize: '14px' }}>
                  Prazo para execução de serviços: {props?.values?.deadline}{' '}
                  dias
                </text>
              ) : (
                <></>
              )}
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <text style={{ fontSize: "14px" }}>Garantia</text>
              </div> */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ fontSize: '14px' }}>
                  Serviços Autorizados:______________________________
                </text>
              </div>
            </div>
            {props.values.observation ? (
              <div>
                <text style={{ fontSize: '14px' }}>OBSERVAÇÕES</text>
                <div style={{ fontSize: '14px', overflowWrap: 'break-word' }}>
                  {props.values.observation}
                </div>
              </div>
            ) : (
              <div>
                <text style={{ fontSize: '14px' }}>OBSERVAÇÕES</text>
                <div style={{ fontSize: '14px', overflowWrap: 'break-word' }}>
                  {props.company.budgetNote}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
});

export default ExternalBudgetPrint;
