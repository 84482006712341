import React, { useEffect, useState } from 'react';
import { HourInput, MoneyInput } from '../../../components/Input/Input';
import { BsTrashFill } from 'react-icons/bs';
import { BUDGET_TABS } from '../utils/enum';
import { InputText } from '../../../components/Input/InputText';
import './styles.scss';

export const ColumnsServiceRenderer = ({ changeValue, data, budget }) => {
  const [quantity, setQuantity] = useState(1);
  const handleTimeValueChange = (value) => {
    changeValue(
      'time',
      data,
      value,
      data?.editedHour ? data?.editedHour : data.time !== value
    );
  };
  const handleQuantityChange = (value) => {
    changeValue(
      'quantity',
      data,
      Number(value),
      data?.editedHour ? data?.editedHour : data.time !== value
    );
  };

  const handleUnitaryValueChange = (value) => {
    changeValue('unitaryValue', data, value);
  };

  const parseCurrencyStringToNumber = (value) => {
    if (typeof value !== 'string') {
      return NaN;
    }
    const formattedValue = value.trim().replace('.', '').replace(',', '.');
    const numericValue = parseFloat(formattedValue);
    return isNaN(numericValue) ? NaN : numericValue;
  };

  const totalAeService =
    parseCurrencyStringToNumber(data.unitaryValue) * quantity;

  const totalValueFn = () => {
    if (budget === BUDGET_TABS.BODYSHOP) {
      if (isNaN(data.value)) {
        return 'R$0';
      } else {
        return data.value;
      }
    } else {
      return isNaN(totalAeService)
        ? data.unitaryValue * quantity
        : totalAeService;
    }
  };

  useEffect(() => {
    handleQuantityChange(quantity <= 0 ? 1 : quantity);
  }, [quantity]);
  useEffect(() => {
    setQuantity(data.quantity);
  }, []);

  // console.log(data.unitaryValue);

  return (
    <div
      style={{ maxHeight: '100%', height: '100%', padding: '8px 0' }}
      className="column-service"
    >
      <div style={{ paddingLeft: '10px' }}>{data.title}</div>
      <div className="column-service-renderer-container">
        <div className="field-actions-budget budget-service-hour">
          {budget === BUDGET_TABS.BODYSHOP ? (
            <HourInput
              type="number"
              className="budget-service-input"
              onChange={handleTimeValueChange}
              placeholder={'Valor hora'}
              value={data.time}
            />
          ) : (
            <InputText
              onBlur={(event) => {
                if (event.target.value <= 0 || !event.target.value) {
                  setQuantity(1);
                }
              }}
              className="inputHeight"
              onChange={(event) => {
                const value = event.target.value;
                setQuantity(value);
              }}
              value={quantity}
              type="number"
            />
          )}
        </div>
        <div className="field-actions-budget budget-service-unit">
          <MoneyInput
            type="number"
            className="budget-service-input"
            onChange={handleUnitaryValueChange}
            placeholder={'Valor unit'}
            value={data.unitaryValue}
          />
        </div>
        <div className="field-actions-budget budget-service-total">
          <MoneyInput
            type="number"
            disabled={true}
            className="budget-service-input"
            onChange={(value) => changeValue('value', data, value)}
            value={totalValueFn()}
          />
        </div>
      </div>
      {data?.services && data?.services?.length > 0 && (
        <>
          <div className="column-service-packages" />
          {data?.services?.map((s) => (
            <div key={s.title} style={{ paddingLeft: '10px' }}>
              - {s.title}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const ActionCell = ({ data, onRemove }) => {
  return (
    <div className="field-actions">
      <BsTrashFill
        className="icon-action trash"
        onClick={() => onRemove(data)}
      />
    </div>
  );
};
